<template>
	<div>
		<div class="post-confirm">
			<div class="post-detail">
				<div class="post-title">企业登记信息确认</div>
				<div class="post-cell">
					<div class="post-item">
						<div class="post-left">招聘会名称：</div>
						<div class="post-right">{{ confirminfo.czph }}</div>
					</div>
					<div class="post-item">
						<div class="post-left">报名企业：</div>
						<div class="post-right">{{ confirminfo.cComName }}</div>
					</div>
					<!-- <div class="post-item" v-if="!type">
						<div class="post-left">预定展位：</div>
						<div class="post-right">{{ confirminfo.cZanw }}</div>
					</div> -->
					<div class="post-item" v-if="!type">
						<div class="post-left">应交费用：</div>
						<div class="post-right">{{ confirminfo.iMoney }}</div>
					</div>
					<div class="post-item" v-if="type">
						<div class="post-left">直播开始时间：</div>
						<div class="post-right">{{ confirminfo.dZbStartTime }}</div>
					</div>
					<div class="post-item" v-if="type">
						<div class="post-left">直播结束时间：</div>
						<div class="post-right">{{ confirminfo.dZbEndTime }}</div>
					</div>
					<div class="post-item">
						<div class="post-left">招聘会状态：</div>
						<div :class="[
                'post-right',
                confirminfo.cQr != null && confirminfo.cQr != 0 ? 'green': 'red',]">
							{{confirminfo.cQr != null && confirminfo.cQr != 0  ? "信息已确认"  : "信息未确认"}}
						</div>
					</div>
				</div>
			</div>
			<div class="post-pay">
				<div class="postpay-list">
					请招聘企业返回确认企业信息及岗位信息，无误后再进行信息确认，确认后将不可更改
				</div>
			</div>
		</div>
		<div class="jobfair-next" v-if="confirminfo.cQr == null || confirminfo.cQr == 0">
			<el-button @click="back">上一步</el-button>
			<el-button type="primary" @click="confirm">确认信息</el-button>
		</div>
	</div>
</template>
<script>
	import {
		confirmonlinecompany,
		confirmzphcompany
	} from "@/api/company";
	import {
		isnull
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	export default {
		props: {
			id: {
				type: Number,
			},
			type: {
				type: Boolean,
			},
			zw: {
				type: Boolean,
			},
			confirminfo: {
				type: Object,
			},
		},
		data() {
			return {
				defimgurl: global.baseimgurl,
				info: {},
				jobfairtype: 2,
				userinfo: "",
			};
		},
		created() {
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
		},
		methods: {
			confirm() {
				if (this.type) {
					this.confirmonlinecompany();
				} else {
					this.confirmzphcompany();
				}
			},

			//线上确认信息
			confirmonlinecompany() {
				var _this = this;
				var url = "";
				var par = {
					id: _this.userinfo.coM_ID,
					uid: _this.id,
				};

				confirmonlinecompany(par).then((res) => {
					if (res.success) {
						this.$emit("confirmonBind", {
							data: true
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//线下确认信息
			confirmzphcompany() {
				var _this = this;
				var url = "";
				var par = {
					cid: _this.userinfo.coM_ID,
					id: _this.id,
				};

				confirmzphcompany(par).then((res) => {
					if (res.success) {
						this.$emit("confirmonBind", {
							data: true
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			back() {
				if (!this.zw) {
					var i = 3;
				} else {
					var i = 4;
				}
				this.$emit("back", {
					i: i
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.post-confirm {
		// background: #fff;
		// padding: 16px 24px;
		transition: all 0.5s;
		margin-bottom: 12px;

		.post-detail {
			background: #fff;
			padding: 16px 24px;
			margin-bottom: 12px;

			.post-title {
				color: #000;
				margin-bottom: 16px;
			}

			.post-cell {
				.post-item {
					display: flex;
					color: #898989;
					font-size: 14px;
					line-height: 32px;

					.post-left {
						width: 130px;
					}
				}
			}
		}

		.post-pay {
			background: #fff;
			padding: 16px 24px;
			margin-bottom: 12px;

			.postpay-list {
				line-height: 24px;
			}
		}
	}

	.post-confirm-offline {
		// background: #fff;
		// padding: 16px 24px;
		transition: all 0.5s;
		margin-bottom: 12px;

		.post-detail {
			background: #fff;
			padding: 16px 24px;
			margin-bottom: 12px;

			.post-title {
				color: #000;
				margin-bottom: 16px;
			}

			.post-cell {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				.post-hd {
					.post-item {
						display: flex;
						color: #898989;
						font-size: 14px;
						line-height: 32px;

						.post-left {
							width: 130px;
						}

						.red {
							color: #ff0000;
						}
					}
				}

				.post-body {
					margin-right: 120px;

					.post-item {
						display: flex;
						color: #898989;
						font-size: 14px;
						line-height: 32px;

						.post-left {
							width: 100px;
						}

						.post-right {
							img {
								width: 100px;
								height: 100px;
							}
						}
					}
				}
			}
		}

		.post-pay {
			background: #fff;
			padding: 16px 24px;
			margin-bottom: 12px;

			.postpay-list {
				line-height: 24px;
			}
		}
	}

	.jobfair-next {
		background: #fff;
		padding: 12px 15px;
		text-align: center;

		.el-button+.el-button {
			margin-left: 30px;
		}

		.el-button {
			border-radius: 0;
			width: 200px;
		}
	}

	.green {
		color: #1f9b29;
	}

	.yellow {
		color: #ffb800;
	}

	.blue {
		color: #276bf2;
	}

	.orgin {
		color: #fb6703;
	}
</style>
