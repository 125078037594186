<template>
	<div>
		<Header style="display:inline-block;"></Header>
		<div class="main-contain">
			<div class="banner-top">
				<div class="banner-box">
					<div class="banner-info">
						<div class="name">{{ fairinfo.czph ? fairinfo.czph : "无" }}</div>
						<div class="btn">
							<div class="span">
								{{ fairinfo.cZhuti }}
							</div>
						</div>
						<div class="info">
							<div class="info-a">
								举办时间：
								{{fairinfo.cZphDate ? utils.timeFormat(utils.stringToDate(fairinfo.cZphDate), 1) : ""}}
								{{ fairinfo.cZphEndDate   ? "至" + utils.timeFormat(utils.stringToDate(fairinfo.cZphEndDate),1):fairinfo.cZphDate ? "无"  : ""}}
							</div>
							<div class="info-a">主办单位：{{fairinfo.cZhuban}}</div>
							<div class="info-a">承办单位：{{fairinfo.cChenban}}</div>
						</div>
						<div class="info-b">
							<div class="flexsub top-banner-qy">企业数量：<span>{{fairinfo.signCompanyNum}}</span> 家</div>
							<div class="flexsub top-banner-gw">岗位数量：<span>{{fairinfo.jobNum}}</span> 个</div>
						</div>
					</div>
				</div>
			</div>
			<div class="search">
				<div class="home-select">
					<div class="select-contain">
						<el-form>
							<el-form-item>
								<el-col :span="24" style="display:flex;">
									<el-input v-model="selfrom.searchKey" placeholder="搜索职位、公司"></el-input>
								</el-col>
								<el-col :span="4">
									<el-button type="primary" @click="selfrom.pageNumber = 1;jobfairdetailclist();">搜索
									</el-button>
								</el-col>
							</el-form-item>
						</el-form>
						<div class="resume">
							<el-button type="primary" @click="sign">
								企业报名
							</el-button>
						</div>
					</div>
				</div>
			</div>
			<div class="mian">
				<div :class="['info_mian', jobfairtype == 1 ? 'bj' : '']" v-if="userinfo && userinfo.userType != 1">
					<!-- 招聘会详情 start -->
					<!-- 步骤条 start -->
					<div class="jobfair-steps" v-if="jobfairtype != 1">
						<div class="steps-cell">
							<div class="steps-item">
								<div class="steps-sort"><span>1</span></div>
								<div class="steps-name">填写单位信息</div>
								<div class="steps-img">
									<img src="../../assets/img/step1.png" alt="" />
								</div>
							</div>
							<div :class="[' steps-item ', jobfairtype > 2 ? '' : 'next']">
								<div class="steps-sort"><span>2</span></div>
								<div class="steps-name">招聘岗位</div>
								<div class="steps-img">
									<img v-if="jobfairtype > 2" src="../../assets/img/step1.png" alt="" />
									<img v-else src="../../assets/img/step2.png" alt="" />
								</div>
							</div>
							<div v-if="zw" :class="['steps-item', jobfairtype > 3 ? '' : 'next']">
								<div class="steps-sort"><span>3</span></div>
								<div class="steps-name">选取展位</div>
								<div class="steps-img">
									<img v-if="jobfairtype > 3" src="../../assets/img/step1.png" alt="" />
									<img v-else src="../../assets/img/step2.png" alt="" />
								</div>
							</div>

							<div
								:class="['steps-item', (!zw && jobfairtype > 3) || (zw && jobfairtype > 4)? '' : 'next',]">
								<div class="steps-sort">
									<span>{{ !zw ? 3 : 4 }}</span>
								</div>
								<div class="steps-name">信息确认</div>
							</div>
						</div>
					</div>
					<jobfairCompany v-if="jobfairtype == 2" :id="fairinfo.id" :type="type" :companyInfo="companyInfo"
						@companyBind="companyBind" @back="back"></jobfairCompany>
					<jobfairPost v-if="jobfairtype == 3" :id="fairinfo.id" :type="type" :cDaxing="cDaxing"
						@postBind="postBind" @back="back"></jobfairPost>
					<jobfairUnits v-if="jobfairtype == 4" :id="fairinfo.id" :type="type" :confirminfo="confirminfo"
						@back="back" @zanwBind="zanwBind"></jobfairUnits>
					<jobfairconfirm v-if="jobfairtype == 5" :id="fairinfo.id" :type="type" :zw="zw"
						:confirminfo="confirminfo" @back="back" @confirmonBind="confirmonBind"></jobfairconfirm>
				</div>

				<div class="home-company" v-if="jobfairtype == 1">
					<div class="company-content">
						<div class="company-cell flex1">
							<div class="company-item" v-for="(cell, j) in companylist" :key="j"
								@mouseover="start && start != '准备中' ? enter(cell) : ''"
								@mouseleave="start && start != '准备中' ? leave() : ''">
								<div class="company-logo">
									<el-image class="img" :src="setcompanylogo(cell.coM_LOGO, 'deflogo')"
										fit="contain ">
									</el-image>
								</div>
								<div class="company-name" v-if="start && start != '准备中'">
									{{ cell.cComName }}
								</div>
								<el-tooltip v-else :content="cell.cComName" placement="top">
									<div class="company-name">
										{{ cell.cComName }}
									</div>
								</el-tooltip>
								<div class="company-num">
									<b>{{ cell.counts }}</b>个热招岗位
								</div>
								<div class="company-post" :class="{ hoveractive: isActive && cell.iComid == num }">
									<div class="post-item" v-for="(cont, n) in analysisjob(cell.info)" :key="n">
										<div class="post-name">{{ cont.postname }}</div>
										<div class="post-salary">{{ cont.salary }}</div>
									</div>
									<div class="post-more">
										<el-button size="mini" round @click="
                    goto('/company', {
                      id: cell.iComid,
                      zid: fairinfo.id,
                    })
                  ">查看更多>></el-button>
									</div>
								</div>
							</div>
						</div>
						<el-empty style="background: #fff" :image-size="200" v-if="companylist.length < 1"></el-empty>
					</div>
					<div style="text-align: center; margin-top: 10px">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="selfrom.pageNumber" :page-sizes="[32, 48, 64, 80, 96, 112, 128]"
							:page-size="selfrom.pageSize" layout="total, sizes, prev, pager, next, jumper"
							:total="total">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>
<script>
	import {
		Getjobfairdetail,
		jobfairdetailclist
	} from "@/api/home";
	import {
		getonlinejobfaircompany,
		getofflinejobfaircompany,
		confirmoffline,
		confirmonlinecompanystatus,
		jobfairview,
	} from "@/api/company";
	import {
		isnull
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import nonli from "@/assets/js/nonli";
	import global from "@/assets/js/globalconfig";
	import jobfairCompany from "./jobfairCompany.vue";
	import jobfairPost from "./jobfairPost.vue";
	import jobfairUnits from "./jobfairUnits.vue";
	import jobfairconfirm from "./jobfairconfirm.vue";
	import Header from "@/components/header.vue";
	import Footer from "@/components/footer1.vue";

	export default {
		components: {
			Header,
			Footer,
			jobfairCompany,
			jobfairPost,
			jobfairUnits,
			jobfairconfirm,
		},
		data() {
			return {
				defimgurl: global.baseimgurl,
				fairinfo: {},
				jobfairtype: 1,
				type: false,
				userinfo: "",
				confirminfo: {},
				gotoType: false,
				cDaxing: false,
				zw: false,
				companylist: [],
				selfrom: {
					pageNumber: 1,
					pageSize: 32,
					searchKey: "",
				},
				total: 0,
				isActive: false,
				companyInfo: {},
				start: "",
				JoinState: "",
				cQr: 3,
				timer: 0,
				enddate: "",
				tian: "",
				nonlidata: "",
				school: false,
			};
		},
		created() {
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			var id = this.$route.query.id;
			if (!isnull(this.$route.query.school)) {
				this.school = this.$route.query.school;
			}
			this.gotoType = this.$route.query.gotoType ? true : false;
			if (!isnull(id)) {
				this.Getinfo(id);
			}
		},
		methods: {
			Getinfo(id) {
				var _this = this;
				Getjobfairdetail({
					id: id
				}).then((res) => {
					if (res.success) {
						_this.fairinfo = res.data;
						if (res.data.bIsZbzp) {
							_this.type = true;
						}
						_this.zw == res.data.zanwNum > 0 ? true : false;
						_this.cDaxing = res.data.cDaxing == 99 ? true : false;
						var data = _this.utils.nowInDateBetwen(
							res.data.cZphDate,
							_this.utils.dateChange(1, utils.stringToDate(res.data.cZphEndDate))
						);

						var Joindata = _this.utils.nowInDateBetwen(
							res.data.cJoinDate,
							_this.utils.dateChange(1, utils.stringToDate(res.data.cJoinEndDate))
						);

						if (data == 1 && Joindata == 1) {
							_this.start = "准备中";
						} else if (data == 2) {
							_this.start = "已结束";
						} else if (data != 2 && data != 1) {
							_this.start = "举办中";
						}
						if (data != 2 && !_this.school) {
							if (Joindata == 1) {
								_this.JoinState = "报名未开始";
							} else if (Joindata == 2) {
								_this.JoinState = "报名已结束";
							} else {
								_this.JoinState = "报名中";
								_this.timer = setInterval(_this.test, 1000);
							}
						} else {
							_this.JoinState = "报名已结束";
						}

						var cZphDate = res.data.cZphDate ?
							this.Ncheage(utils.stringToDate(res.data.cZphDate)) :
							"";
						var cZphEndDate = res.data.cZphEndDate ?
							"至" + this.Ncheage(utils.stringToDate(res.data.cZphEndDate)) :
							"";
						this.nonlidata = cZphDate + cZphEndDate;
						_this.jobfairview(id);
						_this.id = id;
						_this.jobfairdetailclist();
						if (this.userinfo) {
							if (this.userinfo.userType != 1) {
								if (this.type) {
									this.confirmonlinecompanystatus(2);
								} else {
									_this.confirmoffline(2);
								}
							}
						}
					}
				});
			},

			jobfairdetailclist() {
				var _this = this;
				this.selfrom.id = _this.id;
				jobfairdetailclist(this.selfrom).then((res) => {
					if (res.success) {
						_this.companylist = res.data.rows;
						_this.total = res.data.total;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			jobfairview(id) {
				jobfairview({
					id: id
				}).then((res) => {
					if (res.success) {}
				});
			},
			sign() {
				if (this.userinfo && this.userinfo.userType != 1) {
					if (this.type) {
						this.getonlinejobfaircompany();
					} else {
						this.getofflinejobfaircompany();
					}
				}else{
					if (!this.userinfo || !this.userinfo.coM_ID) {
						this.resolvewin("/loginc", {
							type: 1,
							isback: 1
						}, 1);
					} else{
					}
				}
			},
			//获取线上单位信息
			getonlinejobfaircompany(type) {
				var _this = this;
				var par = {
					id: _this.userinfo.coM_ID,
					uid: _this.fairinfo.id,
				};
				getonlinejobfaircompany(par).then((res) => {
					if (res.success) {
						if (res.data.data) {
							if (!type) {
								this.confirmonlinecompanystatus(1);
							}

							this.companyInfo = res.data.data;
						} else {
							_this.jobfairtype = 2;
						}
					} else {
						_this.jobfairtype = 2;
						// _this.$message.error(res.msg);
					}
				});
			},
			//获取线下单位信息
			getofflinejobfaircompany(type) {
				var _this = this;
				var par = {
					cid: _this.userinfo.coM_ID,
					id: _this.fairinfo.id,
				};
				getofflinejobfaircompany(par).then((res) => {
					if (res.success) {
						if (!type) {
							_this.confirmoffline(1);
						}
						this.companyInfo = res.data;
					} else {
						_this.jobfairtype = 2;
					}
				});
			},
			companyBind(e) {
				if (e.data) {
					this.jobfairtype = 3;
					if (this.type) {
						this.getonlinejobfaircompany(1);
					} else {
						this.getofflinejobfaircompany(1);
					}
				}
			},
			goto(url, data) {
				this.resolvewin(url, data, 1);
			},
			postBind(e) {
				if (e.data) {
					if (!this.zw) {
						this.jobfairtype = 5;
						this.confirm();
					} else {
						this.jobfairtype = 4;
					}
				}
			},
			zanwBind(e) {
				if (e.data) {
					this.jobfairtype = 5;
					this.confirmoffline();
				}
			},
			confirmonBind(e) {
				if (e.data) {
					this.confirm();
				}
			},
			confirm() {
				if (this.type) {
					this.confirmonlinecompanystatus();
				} else {
					this.confirmoffline();
				}
			},
			handleSizeChange(val) {
				this.selfrom.pageSize = val;
				this.selfrom.pageNumber = 1;
				this.jobfairdetailclist();
			},
			handleCurrentChange(val) {
				this.selfrom.pageNumber = val;
				this.jobfairdetailclist();
			},
			//判断线上是否已到确认页面
			confirmonlinecompanystatus(i) {
				var _this = this;
				var par = {
					id: _this.userinfo.coM_ID,
					uid: _this.fairinfo.id,
				};
				confirmonlinecompanystatus(par).then((res) => {
					if (res.success) {
						// if (!i) {
						//   _this.jobfairtype = 5;
						// }

						if (res.data.rows.length > 0) {
							_this.cQr = res.data.rows[0].cQr;
							if (i != 2) {
								_this.confirminfo = res.data.rows[0];
								if (res.data.rows[0].cQr == 1) {
									_this.jobfairtype = 5;
								} else {
									if (i == 1) {
										_this.jobfairtype = 3;
									}
								}
							} else {
								if (i == 1) {
									_this.jobfairtype = 3;
								}
							}
						}
					} else {
						// _this.$message.error(res.msg);
					}
				});
			},
			//判断线下是否已到确认页面
			confirmoffline(i) {
				var _this = this;

				var par = {
					id: _this.userinfo.coM_ID,
					uid: _this.fairinfo.id,
				};
				confirmoffline(par).then((res) => {
					if (res.success) {
						if (res.data.table.length > 0) {
							_this.cQr = res.data.table[0].cQr;
							if (i != 2) {
								_this.confirminfo =
									res.data.table.length > 0 ? res.data.table[0] : "";
								_this.confirminfo.cZanw =
									res.data.table1.length > 0 ? res.data.table1[0].cZanw : "";
								if (res.data.table[0].cQr == 1) {
									_this.jobfairtype = 5;
								} else {
									if (res.data.table1.length > 0) {
										_this.jobfairtype = 5;
									} else {
										if (i == 1) {
											_this.jobfairtype = 3;
										}
									}
								}
							}
						} else {
							if (i == 1) {
								_this.jobfairtype = 3;
							}
						}
					} else {
						// _this.$message.error(res.msg);
					}
				});
			},
			back(e) {
				this.jobfairtype = e.i;
			},
			enter(cell) {
				this.isActive = true;
				this.num = cell.iComid;
			},
			analysisjob(datas) {
				var data = [];
				datas.split(",").forEach((item) => {
					if (data.length < 3) {
						var a = item.split("|");
						var sp = a[1].split("-");
						data.push({
							postname: a[0],
							salary: this.setsalary(sp[0], sp[1]),
						});
					}
				});
				return data;
			},
			setsalary(a, b) {
				var data = [];
				if (!isnull(a)) {
					if (a < 100 && a > 0) {
						data.push(a + "k");
					} else if (a >= 1000) {
						data.push(a / 1000 + "k");
					} else {
						data.push(a);
					}
				}
				if (!isnull(b)) {
					if (b < 100 && b > 0) {
						data.push(b + "k");
					} else if (b >= 1000) {
						data.push(b / 1000 + "k");
					} else {
						data.push(b);
					}
				}
				if (data.length === 0) {
					return "面议";
				} else {
					return data.join("-");
				}
			},
			leave() {
				this.isActive = false;
			},
			setcompanylogo(url, data) {
				return isnull(url) ?
					require(`@/assets/img/logos.png`) :
					this.defimgurl + url;
			},
			zphtype(i) {
				switch (i) {
					case 0:
						return "普通招聘会";
						break;
					case 1:
						return "大型招聘会";
						break;
					case 2:
						return "赴外招聘会";
						break;
					case 3:
						return "直播招聘会";
						break;
					case 99:
						return "专场招聘会";
						break;
					default:
						break;
				}
			},
			isSign() {
				if (!this.userinfo) {
					return false;
				}
				if (this.userinfo.userType == 1) {
					return false;
				}
				if (this.userinfo.coM_SH == 0) {
					return false;
				}
				if (this.JoinState != "报名中" && this.cQr == 3) {
					return false;
				}
				return true;
			},
			test() {
				var currTimestamp = new Date(this.fairinfo.cJoinEndDate).getTime();
				var targetTimestamp = currTimestamp + 4 * 3600 * 1000;
				var t1 = new Date(targetTimestamp);

				if (new Date(t1) - new Date() <= 0) {
					clearInterval(this.timer);
					this.enddate = "";
					this.tian = "";
				} else {
					var d = this.utils.cd(t1, new Date(), "d");
					var h = this.utils.cd(t1, new Date(), "h");
					var m = this.utils.cd(t1, new Date(), "m");
					var s = this.utils.cd(t1, new Date(), "s");

					var enddate = "";

					if (d > 0) {
						this.tian = d + "天";
						enddate = h + "时" + m + "分" + s + "秒";
					} else if (h > 0) {
						enddate = h + "时" + m + "分" + s + "秒";
					} else if (h == 0 && m > 0) {
						enddate = m + "分" + s + "秒";
					} else {
						enddate = s + "秒";
					}
					this.enddate = enddate;
				}
			},
			Ncheage(date) {
				if (!date) {
					return false;
				}
				var data = date.split("-");
				var Ndata = nonli.Lunar.toLunar(data[0], data[1], data[2]);
				return Ndata[5] + Ndata[6];
			},
		},
	};
</script>
<style lang="scss" scoped>
	.banner-top {
		width: 100%;
		height: 400px;
		background: url(../../assets/img/jobfair/1.jpg) top center no-repeat;
		position: relative;

		.banner-box {
			width: 1200px;
			height: 400px;
			margin: 0px auto;
			position: relative;
		}

		.banner-head {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.banner-logo {
				width: 180px;
				height: 40px;

				img {
					width: 100%;
				}
			}

			.banner-back {
				padding: 4px 12px;
				background: #faa239;
				color: #fff;
				border-radius: 4px;
				display: flex;
				align-items: center;
				cursor: pointer;

				img {
					width: 20px;
					height: 20px;
					margin-right: 8px;
				}
			}
		}

		.banner-info {
			display: flex;
			flex-direction: column;
			align-items: center;
			.name {
	margin-top: 55px;
				color: #fff;
				font-size: 80px;font-family: 黑体;
				font-weight: bold;
			}

			.btn {
				padding: 2px;
				 margin-top: 35px;

				.span {
					font-family: 楷体;
					height: 40px;
					border-radius: 50px;
					line-height: 40px;
					font-size: 25px;
					color: #fff;
					padding: 0 40px;
				}
			}

			.info {
				font-size: 16px;
				color: #fff;
				margin-top: 15px;

				.info-a {
					margin-top: 8px;
				}
			}

			.info-b {
				margin-top: 10px;
				border-radius: 8px;
				background-color: white;
				padding: 5px;
				display: flex;
				flex-direction: center;
				align-items: center;
			}

			.info-b div {
				margin-left: 20px;
				font-size: 18px;
				padding-left: 35px;
				line-height: 35px;
			}

			.info-b span {
				color: #08BB91;
				font-size: 26px;
			}
.top-banner-qy{
	background: url(../../assets/img/jobfair/15.png) no-repeat left center;
}
.top-banner-gw{
	background: url(../../assets/img/jobfair/16.png) no-repeat left center;
}
		}

		.banner-search {
			position: absolute;
			left: 150px;
			right: 150px;
			bottom: 48px;
		}

		.el-form {
			width: 100%;
			background: #fff;

			.el-form-item::v-deep {
				margin-bottom: 0;
			}

			.el-select ::v-deep {
				.el-input {
					padding-top: 4px;
					padding-bottom: 4px;

					.el-input__inner {
						border: none;
						border-radius: 0;
						font-size: 14px;
					}

					.el-input__inner::placeholder {
						color: #333;
					}
				}
			}

			.el-input ::v-deep {
				padding-top: 4px;
				padding-bottom: 4px;

				.el-input__inner {
					border: none;
					border-radius: 0;
					border-left: 1px solid #eee;
				}
			}

			.el-button {
				width: 100%;
				text-align: center;
				border-radius: 0;
				padding-top: 16px;
				padding-bottom: 16px;
				font-size: 16px;
			}
		}

		.mobileCode {
			position: absolute;
			right: 160px;
			top: 270px;
			font-size: 12px;
			color: white;
			text-align: center;

			img {
				width: 150px;
				border-radius: 4px;
				margin-bottom: 10px;
			}
		}
	}

	.mian {
		width: 1200px;
		margin: 20px auto;
	}

	.search {
		height: 130px;
		position: relative;
		background: none;
	}

	.home-select {
		width: 1200px;
		height: 130px;
		margin: 0px auto;
		margin-bottom: 16px;
		// background: url(../../assets/img/souso_bg.png) no-repeat;
		// background-size: 50% 100%;
		display: flex;
		align-items: center;

		.select-contain {
			width: 100%;
			// padding: 24px;
			display: flex;

			::v-deep.el-button--primary {
				color: #898989;
				background-color: #ffff;
				border-color: #ffff;
			}

			::v-deep.el-dropdown .el-dropdown__caret-button::before {
				background: #fff;
			}

			.el-form {
				// margin-left: 35%;
				width: 1030px;
				background: #fff;
				height: 56px;

				// display: flex;
				::v-deep.el-form-item__content {
					display: flex;
				}

				.el-form-item::v-deep {
					margin-bottom: 0;
				}

				.el-select ::v-deep {
					.el-input {
						// padding-top: 4px;
						padding-bottom: 4px;

						.el-input__inner {
							border: none;
							border-radius: 0;
							font-size: 14px;

						}

						.el-input__inner::placeholder {
							color: #333;
						}
					}
				}

				.el-input ::v-deep {
					padding: 0;
					border: none;
					height: 56px;

					.el-input__inner {
						height: 56px;
						border: none;
						border-radius: 0;
						border-left: 1px solid #eee;
					}
				}

				.el-button {
					width: 100%;
					height: 56px;
					color: #fff;
					text-align: center;
					border-radius: 0;
					// padding-top: 16px;
					border: 1px solid #0AD7A7;
					font-size: 16px;
					background: #0AD7A7;
				}
			}

			.resume {
				width: 156px;
				margin-left: 14px;

				.el-button {
					width: 100%;
					height: 56px;

					display: flex;
					align-content: center;
					justify-content: center;
					border: 1px solid #0AD7A7;
					color: #0AD7A7;
					text-align: center;
					border-radius: 0;
					// padding-top: 16px;
					// padding-bottom: 10px;
					font-size: 16px;
					// background: #F66308;

					::v-deepspan {
						display: flex;
						align-items: center;
						margin: auto 0;
					}
				}
			}

			.hot-word {
				text-align: left;
				font-size: 16px;
				color: #787878;

				a {
					padding: 0px 12px;
					color: #409eff;
					text-decoration: none;
				}
			}
		}

		.contact {
			width: 20%;
			height: 100px;

			.img {
				height: 100px;
			}
		}
	}

	.hot_search {
		display: flex;
		position: absolute;
		top: 94px;
		left: 41.5%;

		.position {
			color: rgba(255, 255, 255, 100);
			font-size: 13px;
			font-family: SourceHanSansSC-light;

			span {
				margin-right: 10px;
			}
		}
	}

	.bj {
		background: url(../../assets/img/dise.png) no-repeat;
		background-size: cover;
	}

	.info_mian {
		// background-color: #fff;
		width: 1200px;

		margin-top: 15px;

		// 招聘会详情
		.fairInfo {
			width: 100%;
			padding: 40px 25px;
			box-sizing: border-box;
			display: flex;
			position: relative;
			margin-bottom: 15px;

			.left {
				width: 230px;

				.left-time {
					width: 180px;
					height: 160px;
					background: url(../../assets/img/djs.png) no-repeat;
					background-size: cover;
					margin-top: -10px;
					display: flex;
					align-items: center;
					justify-content: center;

					.time {
						color: #cf4435;
						font-weight: bold;

						text-align: center;
						font-size: 16px;

						.tian {
							font-size: 18px;
							margin-top: 46px;
							margin-bottom: 5px;
						}
					}
				}
			}

			.center {
				width: 780px;
			}

			.fairTitle {
				font-size: 22px;
				color: #333;
				font-weight: bold;
				margin-bottom: 15px;
			}

			.fairlabel {
				font-size: 14px;
				margin-top: 15px;
				margin-bottom: 5px;
				display: flex;
				align-items: center;

				.label {
					padding: 4px 10px;
					border-radius: 4px;
					background: #fdd69f;
					color: #c84031;
					font-size: 12px;
					margin-right: 10px;
				}

				.gyx {
					border-radius: 4px;
					color: #666;
					font-size: 16px;
					font-weight: bold;
					margin-right: 10px;
				}
			}

			.fairLine {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				width: 100%;

				.fairItem {
					display: flex;
					align-items: center;
					margin-top: 10px;
					font-size: 15px;
					font-weight: bold;

					.fairName {
						display: flex;
						align-items: center;

						img {
							width: 25px;
							height: 25px;
							margin-right: 10px;
						}
					}

					.fairtext {
						color: #333;
					}
				}

				.fairItems {
					width: 100%;
					display: flex;
					align-items: center;
					margin-top: 10px;
					font-size: 15px;
					font-weight: bold;

					.fairName {
						display: flex;
						align-items: center;

						img {
							width: 25px;
							height: 25px;
							margin-right: 10px;
						}
					}

					.fairtext {
						color: #333;
					}
				}
			}

			.right {
				display: flex;
				align-items: flex-end;

				.fairBtn {
					width: 150px;
					height: 40px;
					background-image: linear-gradient(#f85b4a, #c94132);
					color: #fdf4e0;
					font-size: 16px;
					line-height: 40px;
					text-align: center;
					border-radius: 8px;
					font-weight: bold;
					cursor: pointer;
				}
			}
		}

		.fairIntro {
			width: 100%;
			padding: 20px 25px;
			box-sizing: border-box;
			background: white;

			.intro {
				display: flex;
				align-items: center;

				.introLine {
					width: 8px;
					height: 22px;
					border-radius: 10px;
					background: #276bf2;
					margin-right: 10px;
				}

				.introText {
					font-size: 16px;
					color: #333;
					font-weight: bold;
				}
			}

			.fairContent {
				margin-top: 20px;
				line-height: 2;
				padding-left: 20px;
				margin-bottom: 50px;
			}
		}

		.fairbtn {
			width: 100%;
			display: flex;
			justify-content: center;
			margin-top: 20px;

			.cancel,
			.preservation {
				width: 200px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				margin: 0 15px;
			}

			.cancel {
				border: 1px solid #276bf2;
				box-sizing: border-box;
				color: #276bf2;
				cursor: pointer;
			}

			.preservation {
				background: #276bf2;
				color: white;
				cursor: pointer;
			}
		}

		.jobfair-steps {
			background: #fff;
			padding: 16px 24px;
			transition: all 0.5s;
			margin-bottom: 12px;

			.steps-cell {
				display: flex;
				justify-content: space-around;

				.steps-item {
					display: flex;
					align-items: center;
					position: relative;

					.steps-sort {
						width: 24px;
						height: 24px;
						padding: 3px;
						border-radius: 16px;
						border: 1px solid #66b1ff;
						background: #fff;
						text-align: center;
						position: relative;
						margin-right: 24px;

						span {
							display: block;
							border-radius: 12px;
							background: #66b1ff;
							color: #fff;
							width: 24px;
							height: 24px;
							line-height: 24px;
						}
					}

					.steps-name {
						color: #66b1ff;
					}

					.steps-img {
						width: 110px;

						img {
							width: 100px;
						}
					}
				}

				.next {
					.steps-sort {
						width: 24px;
						height: 24px;
						padding: 3px;
						border-radius: 16px;
						border: 1px solid #ddd;
						background: #fff;
						text-align: center;
						position: relative;
						margin-right: 24px;

						span {
							display: block;
							border-radius: 12px;
							background: #ddd;
							color: #999;
							width: 24px;
							height: 24px;
							line-height: 24px;
						}
					}

					.steps-name {
						display: flex;
						align-items: center;
						color: #909090;
					}
				}
			}
		}
	}

	.home-company {
		width: 1170px;
		margin: 0px auto;
		background: #fff;
		padding: 15px;
		border-radius: 4px;
		margin-bottom: 20px;
		margin-top: 20px;

		.company-content {
			.company-title {
				font-size: 18px;
				color: #333;
				margin-bottom: 16px;
				margin-left: 25px;
				font-weight: bold;
			}

			.company-cell {
				.company-item {
					width: 275px;
					height: 135px;
					// padding: 12px 0;
					background: #f2f2f2;
					margin-bottom: 15px;
					margin-left: 15px;
					position: relative;
					overflow: hidden;

					.company-logo {
						height: 50px;
						width: 275px;
						padding-top: 12px;
						text-align: center;

						.img {
							width: 250px;
							height: 50px;
						}
					}

					.company-name {
						font-size: 14px;
						line-height: 32px;
						color: #333;
						text-align: center;
						width: 70%;
						margin: 0 auto;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.company-num {
						padding: 8px 0px;
						border-radius: 2px;
						border: 1px solid #eee;
						font-size: 12px;
						background: #fff;
						width: 180px;
						margin: 0px auto;
						text-align: center;

						b {
							color: #ff0000;
						}
					}

					.company-post {
						position: absolute;
						padding: 16px;
						left: 0;
						right: 0;
						top: 140px;
						height: 110px;
						background: rgba($color: #000000, $alpha: 0.6);
						transition: all 0.5s;

						.post-item {
							display: flex;
							align-items: center;
							justify-content: space-between;
							margin-bottom: 10px;

							.post-name {
								color: #fff;
								font-size: 12px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								max-width: 190px;
							}

							.post-salary {
								font-size: 12px;
								color: #ff0000;
								max-width: 50px;
							}
						}

						.post-more {
							text-align: center;
						}
					}

					.hoveractive {
						top: 0;
					}
				}
			}
		}
	}
</style>
