var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"jobfair-units"},[_c('div',{staticClass:"choice-units"},_vm._l((_vm.unitsdata),function(item,index){return _c('div',{key:index,staticClass:"units-item",on:{"click":function($event){return _vm.unitschange(item, index)}}},[_c('div',{class:[
              'units-img',
              { 'bg-temp': item.type == 3 },
              { 'bg-sited': item.type == 2 },
              { 'bg-sit': item.type == 1 },
              { 'bg-nosit': item.type == 0 },
            ]}),_c('div',{staticClass:"units-num"},[_vm._v(_vm._s(item.cZanw))])])}),0),_c('div',{staticClass:"units-table"},[_vm._m(0),_c('div',{staticClass:"units-table-tbody"},_vm._l((_vm.Xunitsdata),function(item,index){return _c('div',{key:index,staticClass:"units-table-tr"},[_c('div',{staticClass:"th-1f"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticClass:"th-2s"},[_c('span',[_vm._v(_vm._s(item.cZanw))])]),_c('div',{staticClass:"th-3t"},[_vm._v(_vm._s(item.iFee))]),_c('div',{staticClass:"th-4f"},[_vm._v(_vm._s(item.iFee))])])}),0)])]),_c('div',{staticClass:"jobfair-next"},[_c('el-button',{on:{"click":_vm.back}},[_vm._v("上一步")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.offlinezanwid()}}},[_vm._v("保存并下一步")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"units-table-thead"},[_c('div',{staticClass:"th-1f"},[_vm._v("序号")]),_c('div',{staticClass:"th-2s"},[_vm._v("展位名称")]),_c('div',{staticClass:"th-3t"},[_vm._v("展位费")]),_c('div',{staticClass:"th-4f"},[_vm._v("应缴费用")])])
}]

export { render, staticRenderFns }