<template>
	<div>
		<div class="jobfair-units">
			<div class="choice-units">
				<div class="units-item" v-for="(item, index) in unitsdata" :key="index"
					@click="unitschange(item, index)">
					<div :class="[
              'units-img',
              { 'bg-temp': item.type == 3 },
              { 'bg-sited': item.type == 2 },
              { 'bg-sit': item.type == 1 },
              { 'bg-nosit': item.type == 0 },
            ]"></div>
					<div class="units-num">{{ item.cZanw }}</div>
				</div>
			</div>
			<div class="units-table">
				<div class="units-table-thead">
					<div class="th-1f">序号</div>
					<div class="th-2s">展位名称</div>
					<div class="th-3t">展位费</div>
					<div class="th-4f">应缴费用</div>
				</div>
				<div class="units-table-tbody">
					<div class="units-table-tr" :key="index" v-for="(item, index) in Xunitsdata">
						<div class="th-1f">{{ index + 1 }}</div>
						<div class="th-2s">
							<span>{{ item.cZanw }}</span>
						</div>
						<div class="th-3t">{{ item.iFee }}</div>
						<div class="th-4f">{{ item.iFee }}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 按钮下一步start -->
		<div class="jobfair-next">
			<el-button @click="back">上一步</el-button>
			<el-button type="primary" @click="offlinezanwid()">保存并下一步</el-button>
		</div>
	</div>
</template>
<script>
	import {
		offlinezanw,
		offlinezanwid
	} from "@/api/company";
	import {
		isnull
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	export default {
		props: {
			id: {
				type: Number,
			},
			type: {
				type: Boolean,
			},
		},
		data() {
			return {
				defimgurl: global.baseimgurl,
				userinfo: "",
				unitsdata: [],
				Xunitsdata: [],
			};
		},
		created() {
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
		},
		watch: {
			id(val) {
				if (!isnull(val)) {
					this.offlinezanw();
				}
			},
		},
		mounted() {
			if (!isnull(this.id)) {
				this.offlinezanw();
			}
		},
		methods: {
			//获取展位信息
			offlinezanw() {
				var _this = this;
				var par = {
					id: _this.userinfo.coM_ID,
					uid: _this.id,
				};
				offlinezanw(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							element.type = element.iComid ? 3 : 1;
							if (element.iComid == _this.userinfo.coM_ID) {
								element.type = 2;
								this.Xunitsdata = [];
								this.Xunitsdata.push(element);
							}
						});
						_this.unitsdata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//选取展位
			unitschange(item, index) {
				if (this.unitsdata[index].type != 3) {
					this.Xunitsdata.splice(0, 1);
					// this.unitsdata[index].type = this.unitsdata[index].type == 1 ? 2 : 1;
					this.unitsdata.forEach((element, i) => {
						if (item.id == element.id) {
							if (element.type == 1) {
								this.Xunitsdata.push(item);
								element.type = 2;
							} else {
								element.type = 1;
							}
						} else {
							element.type = 1;
						}
					});

					// if (this.Xunitsdata.length < 1) {
					//   this.Xunitsdata.push(item);
					// } else {
					//   //   var flag = true;
					//   //   this.Xunitsdata.forEach((element, i) => {
					//   if (this.Xunitsdata[0].id == item.id) {
					//     this.Xunitsdata.splice(i, 1);
					//   } else {
					//     this.Xunitsdata.push(item);
					//   }
					//   //   });
					//   //   if (flag) {
					//   //     this.Xunitsdata.push(item);
					//   //   }
					// }
				}
			},

			//保存展位信息
			offlinezanwid() {
				var _this = this;
				// var zid = "";
				if (_this.Xunitsdata.length < 1) {
					_this.$message.warning("请选取至少一个展位");
					return;
				}
				// this.Xunitsdata.forEach((element) => {
				//   zid = zid + element.id + ",";
				// });
				var par = {
					id: _this.userinfo.coM_ID,
					uid: _this.id,
					zid: _this.Xunitsdata[0].id,
				};
				offlinezanwid(par).then((res) => {
					if (res.success) {
						this.$emit("zanwBind", {
							data: true
						});
						// if (_this.type) {
						//   _this.confirmonlinecompanystatus();
						// } else {
						//   _this.confirmoffline();
						// }
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			back() {
				this.$emit("back", {
					i: 3
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.jobfair-units {
		transition: all 0.5s;
		margin-bottom: 12px;

		.choice-units {
			background: #fff;
			padding: 16px 24px;
			margin-bottom: 12px;
			display: flex;
			flex-wrap: wrap;

			.units-item {
				width: 60px;
				height: 80px;

				.units-img {
					height: 50px;
					width: 50px;
					margin: 0px auto;
				}

				.bg-temp {
					background: url(../../assets/img/unit3.png) center center no-repeat;
					background-size: 80%;
					cursor: not-allowed;
				}

				.bg-sited {
					background: url(../../assets/img/unit2.png) center center no-repeat;
					background-size: 80%;
					cursor: pointer;
				}

				.bg-sit {
					background: url(../../assets/img/units1.png) center center no-repeat;
					background-size: 80%;
					cursor: pointer;
				}

				.units-num {
					text-align: center;
					font-size: 14px;
					color: #787878;
				}
			}
		}

		.units-table {
			background: #fff;
			padding: 16px 24px;
			margin-bottom: 12px;

			.units-table-thead {
				background: #eee;
				color: #000;
				text-align: center;
				display: flex;
				padding: 12px 0;

				.th-1f {
					width: 80px;
				}

				.th-2s {
					width: 160px;
				}

				.th-3t {
					width: 160px;
				}

				.th-4f {
					flex: 1;
				}
			}

			.units-table-tbody {
				.units-table-tr {
					color: #666;
					text-align: center;
					display: flex;
					padding: 12px 0;
					border-top: 1px solid #eee;

					.th-1f {
						width: 80px;
					}

					.th-2s {
						width: 160px;

						span {
							padding: 5px 12px;
							border: 1px solid #fd7d4a;
							background: #fdefea;
							color: #fd7d4a;
						}
					}

					.th-3t {
						width: 160px;
					}

					.th-4f {
						flex: 1;
					}
				}
			}
		}
	}

	.jobfair-next {
		background: #fff;
		padding: 12px 15px;
		text-align: center;

		.el-button+.el-button {
			margin-left: 30px;
		}

		.el-button {
			border-radius: 0;
			width: 200px;
		}
	}
</style>
