<template>
	<div>
		<div class="info_mian">
			<!-- 填写单位信息 start -->
			<div class="companyEdit">
				<div class="editContain">
					<div class="editTitle">
						<img src="../../assets/img/icon.png" alt="" />
						企业信息
					</div>
					<div class="editLine">
						<div class="editItem" style="width: 100%">
							<div class="editName"><b>*</b>公司名称：</div>
							<div class="editInput" style="width: 84.8%">
								<input type="text" v-model="editinfo.coM_NAME" readonly placeholder="请输入" />
							</div>
						</div>
					</div>
					<div class="editLine">
						<div class="editItem">
							<div class="editName"><b>*</b>企业性质：</div>
							<div class="editInput" style="border: none">
								<el-select class="labelselect" v-model="editinfo.coM_NATURE"
									@change="SelectChange($event, 3)" placeholder="请选择">
									<el-option v-for="item in xzdata" :key="item.cName" :label="item.cName"
										:value="item.cCode">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="editItem">
							<div class="editName"><b>*</b>所属行业：</div>
							<div class="editInput" style="border: none">
								<el-select class="labelselect" v-model="editinfo.coM_INDUSTRY"
									@change="SelectChange($event, 0)" placeholder="请选择">
									<el-option v-for="item in hydata" :key="item.cName" :label="item.cName"
										:value="item.cCode">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>
					<div class="editLine">
						<div class="editItem">
							<div class="editName"><b>*</b>联系人：</div>
							<div class="editInput">
								<input type="text" v-model="editinfo.coM_LINKMAN" placeholder="请输入" />
							</div>
						</div>
						<div class="editItem">
							<div class="editName"><b>*</b>联系电话：</div>
							<div class="editInput">
								<input type="text" v-model="editinfo.coM_MPHONE" placeholder="请选择" />
							</div>
						</div>
					</div>
					<div class="editLine">
						<div class="editItem">
							<div class="editName"><b>*</b>所在地区：</div>
							<div class="editInput" style="border: none">
								<el-select class="labelselect" v-model="editinfo.cArea" placeholder="请选择">
									<el-option label="金华市" value="金华市"></el-option>
									<el-option label="婺城区" value="婺城区"></el-option>
									<el-option label="金东区" value="金东区"></el-option>
									<el-option label="开发区" value="开发区"></el-option>
									<el-option label="兰溪市" value="兰溪市"></el-option>
									<el-option label="东阳市" value="东阳市"></el-option>
									<el-option label="义乌市" value="义乌市"></el-option>
									<el-option label="永康市" value="永康市"></el-option>
									<el-option label="浦江县" value="浦江县"></el-option>
									<el-option label="武义县" value="武义县"></el-option>
									<el-option label="磐安县" value="磐安县"></el-option>
									<el-option label="其他地区" value="其他地区"></el-option>
								</el-select>
							</div>
						</div>
					</div>

					<div class="editLine">
						<div class="editItem" style="width: 100%">
							<div class="editName"><b>*</b>通讯地址：</div>
							<div class="editInput" style="width: 84.8%">
								<input type="text" v-model="editinfo.coM_ADDRESS" placeholder="请输入" />
							</div>
						</div>
					</div>
					<div class="editLine" v-if="type">
						<div class="editItem">
							<div class="editName"><b>*</b>直播开始时间：</div>
							<div class="editInput" style="border: none">
								<el-date-picker class="labelselect" v-model="startTime" type="date" format="yyyy-MM-dd"
									placeholder="选择日期">
								</el-date-picker>
							</div>

							<!-- <div class="editTips">不得早于2021-12-31 08：30：00</div> -->
						</div>
					</div>
					<div class="editLine" v-if="type">
						<div class="editItem">
							<div class="editName"><b>*</b>直播结束时间：</div>
							<div class="editInput" style="border: none">
								<el-date-picker class="labelselect" v-model="endTime" type="date" format="yyyy-MM-dd"
									placeholder="选择日期">
								</el-date-picker>
							</div>
							<!-- <div class="editTips">不得早于2021-12-31 08：30：00</div> -->
						</div>
					</div>
				</div>
				<div class="editInfor">
					<div class="editTitle">
						<img src="../../assets/img/icon.png" alt="" />
						公司简介
					</div>
					<div class="editContent">
						<textarea class="textarea" v-model="editinfo.coM_INTRODUCT" cols="30" rows="10"></textarea>
					</div>
				</div>
			</div>
			<!-- 按钮下一步start -->
			<div class="jobfair-next">
				<el-button @click="back">返回</el-button>
				<el-button type="primary" @click="Next(4)">保存并下一步</el-button>
			</div>
			<!-- 按钮下一步end -->
		</div>
	</div>
</template>
<script>
	import {
		opercompanyinfo,
		onlinejobfaircompany,
		offlinejobfaircompany,
		posilistall,
		editzphcompany,
	} from "@/api/company";
	import {
		isnull
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	export default {
		props: {
			id: {
				type: Number,
			},
			type: {
				type: Boolean,
			},
			companyInfo: {
				type: Object,
				default: "",
			},
		},
		data() {
			return {
				defimgurl: global.baseimgurl,
				editinfo: "",
				jobfairtype: "",
				xzdata: [],
				hydata: [],
				startTime: "",
				endTime: "",
			};
		},
		created() {
			this.editinfo = JSON.parse(localStorage.getItem("userinfo"));
			if (this.companyInfo.cComName) {
				this.editinfo.coM_NAME = this.companyInfo.cComName;
				this.editinfo.coM_LINKMAN = this.companyInfo.cLxr;
				this.editinfo.coM_MPHONE = this.companyInfo.cLxdh;
				this.editinfo.coM_NATURE = this.companyInfo.cNature;
				this.editinfo.coM_INDUSTRY = this.companyInfo.cHy;
				this.editinfo.coM_INTRODUCT = this.companyInfo.cIntroduce;
				this.editinfo.coM_ADDRESS = this.companyInfo.cAddress;
				this.startTime = this.companyInfo.dZbStartTime;
				this.endTime = this.companyInfo.dZbEndTime;
			}
			this.editinfo.cArea = '兰溪市';
			this.hyposilistall();
			this.xzposilistall();
		},
		methods: {
			xzposilistall() {
				var _this = this;
				var par = {
					types: "公司性质",
				};
				posilistall(par).then((res) => {
					if (res.success) {
						_this.xzdata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			hyposilistall() {
				var _this = this;
				var par = {
					types: "行业",
				};
				posilistall(par).then((res) => {
					if (res.success) {
						_this.hydata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			Next() {
				var _this = this;
				var editinfo = _this.editinfo;
				if (!editinfo.coM_NAME) {
					_this.$message.warning("请输入公司名称");
					return;
				}
				if (!editinfo.coM_NATURE) {
					_this.$message.warning("请选取企业性质");
					return;
				}
				if (!editinfo.coM_INDUSTRY) {
					_this.$message.warning("请选取所属行业");
					return;
				}
				if (!editinfo.coM_LINKMAN) {
					_this.$message.warning("请输入联系人");
					return;
				}
				if (!editinfo.coM_MPHONE) {
					_this.$message.warning("请输入联系电话");
					return;
				}
				if (!editinfo.coM_ADDRESS) {
					_this.$message.warning("请输入通讯地址");
					return;
				}

				var url = "";
				var par = {
					iComid: editinfo.coM_ID, //公司ID
					cComName: editinfo.coM_NAME, //公司名
					cLxr: editinfo.coM_LINKMAN, //联系人
					cLxdh: editinfo.coM_MPHONE, //手机号码
					cNature: editinfo.coM_NATURE, //公司性质
					cHy: editinfo.coM_INDUSTRY, //所属行业
					cIntroduce: editinfo.coM_INTRODUCT, //公司介绍
					cAddress: editinfo.coM_ADDRESS,
					cArea: editinfo.cArea,
					zphid: this.id,
				};
				if (_this.type) {
					if (!_this.startTime) {
						_this.$message.warning("请选取直播开始时间");
						return;
					}
					if (!_this.endTime) {
						_this.$message.warning("请选取直播结束时间");
						return;
					}

					par.dZbStartTime = _this.startTime;
					par.dZbEndTime = _this.endTime;
					_this.editzphcompany(par);
					// this.opercompanyinfo();
					// onlinejobfaircompany(par).then((res) => {
					//   if (res.success) {
					//     _this.faircompany = res.data;
					//     this.$emit("companyBind", { data: true });
					//   } else {
					//     _this.$message.error(res.msg);
					//     return;
					//   }
					// });
				} else {
					_this.editzphcompany(par);
				}
			},
			editzphcompany(par) {
				var _this = this;
				if (!par.cComName) {
					_this.$message.warning("请输入公司名称");
					return;
				}
				editzphcompany(par).then((res) => {
					if (res.success) {
						// _this.$message.success("保存成功!");
						this.$emit("companyBind", {
							data: true
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			opercompanyinfo() {
				var _this = this;
				var userinfo = _this.editinfo;
				if (!userinfo.coM_NAME) {
					_this.$message.warning("请输入公司名称");
					return;
				}
				var par = {
					COM_ID: userinfo.coM_ID, //公司ID
					COM_NAME: userinfo.coM_NAME, //公司名
					COM_LINKMAN: userinfo.coM_LINKMAN, //联系人
					COM_ZJLB: userinfo.coM_ZJLB, //证件类别
					COM_ZJLB_CODE: userinfo.coM_ZJLB_CODE, //证件编号
					COM_MPHONE: userinfo.coM_MPHONE, //手机号码
					COM_PHONE: userinfo.coM_PHONE, //联系电话
					COM_ZIPCODE: userinfo.coM_ZIPCODE, //邮编
					COM_NATURE: userinfo.coM_NATURE, //公司性质
					COM_SCALE: userinfo.coM_SCALE, //公司规模
					COM_WEBSITE: userinfo.coM_WEBSITE, //公司网址
					COM_INDUSTRY: userinfo.coM_INDUSTRY, //所属行业
					COM_EMAIL: userinfo.coM_EMAIL, //公司邮箱
					COM_HYTYPE: userinfo.coM_HYTYPE, //招聘大类
					COM_VOUCHER: userinfo.coM_VOUCHER, //营业执照
					COM_INTRODUCT: userinfo.coM_INTRODUCT, //公司介绍
					COM_ADDRESS: userinfo.coM_ADDRESS,
					COM_LOGO: userinfo.coM_LOGO,
				};
				opercompanyinfo(par).then((res) => {
					if (res.success) {
						// _this.$message.success("保存成功!");
						this.$emit("companyBind", {
							data: true
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			back() {
				this.$emit("back", {
					i: 1
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.mian {
		width: 1200px;
		margin: 20px auto;
	}

	.info_mian {
		background-color: #fff;
		padding: 20px;
		margin-top: 15px;

		.companyEdit {
			.editTitle {
				font-size: 16px;
				font-weight: bold;
				color: #333;
				display: flex;
				align-items: center;

				img {
					width: 30px;
					height: 30px;
					margin-right: 15px;
				}
			}

			.editContain {
				width: 100%;
				padding: 20px 30px;
				box-sizing: border-box;
				background: white;

				.editLine {
					display: flex;
					margin-top: 25px;
					justify-content: space-between;

					.editItem {
						width: 49.5%;
						color: #333;
						display: flex;
						align-items: center;
						position: relative;

						.editName {
							width: 120px;
							font-size: 14px;
							b {
								color: red;
							}
						}

						.editInput {
							width: 280px;
							height: 40px;
							border-radius: 4px;
							overflow: hidden;
							border: 1px solid rgba($color: #c1bdbd, $alpha: 0.4);

							input {
								width: 100%;
								height: 100%;
								text-indent: 1em;
								padding: 0;
							}

							select {
								width: 100%;
								height: 100%;
								border: none;
							}

							.labelselect {
								height: 40px;

								width: 280px;

								.el-input__inner {
									width: 280px;
								}
							}
						}

						.el-icon-date {
							position: absolute;
							right: 25px;
							color: #999;
						}

						.editTips {
							color: #e84949;
							font-size: 12px;
							position: absolute;
							right: -170px;
						}
					}
				}
			}

			.editInfor {
				width: 100%;
				padding: 20px 30px;
				box-sizing: border-box;
				background: white;
				margin-top: 10px;

				.editContent {
					margin-top: 30px;
					line-height: 2;
				}
			}
		}
	}

	.jobfair-next {
		background: #fff;
		padding: 12px 15px;
		text-align: center;

		.el-button+.el-button {
			margin-left: 30px;
		}

		.el-button {
			border-radius: 0;
			width: 200px;
		}
	}

	textarea {
		width: 100%;
		box-sizing: border-box;
		border: 1px solid rgba(193, 189, 189, 0.4);
		border-radius: 4px;
		text-indent: 1em;
		padding: 10px 5px;
		padding: 10px;
	}
</style>
